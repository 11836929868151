import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.article`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .thank-you-wrapper {
    padding: 0 3rem 3rem 3rem;
  }

  p {
    text-align: left;
  }
`

const BookRoomThanksPage = () => {
  return (
    <Wrapper>
      <div className="thank-you-wrapper">
        <h1>Спасибо за заявку!</h1>
        <p>В ближайшее время с вами свяжется наш менеджер.</p>
      </div>
    </Wrapper>
  )
}

export default BookRoomThanksPage
